import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { objects } from './Objects.js'
import { finishes } from './Finishes.js'

/**
 * Vars
 */
let id = 0
const quantObjects = objects.length
let hash = window.location.hash
if(hash !== '') {
    hash = Number(hash.replace('#', ''))
    if(typeof hash === 'number') id = hash
}
if(id > quantObjects - 1) id = 0
const object = objects[id]

let gltfLoaded = null


/**
 * Base
 */
// Debug
const gui = new dat.GUI()
gui.close()
gui.hide()
const params = {
    ambientLightColor: '#f4f4f4',
    directionalLightColor: '#f4f4f4',
    directionalLightHelperVisible: false,
    // directionalLightShadowMapVisible: true,
}
const ambientLightFolder = gui.addFolder('ambientLight')
const directionalLightFolder = gui.addFolder('directionalLight')


// Canvas
const canvasContainer = document.querySelector('.canvas__container')
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0xf4f4f4);

/**
 * Textures
 */
const materialsFinishes = []
const textureLoader = new THREE.TextureLoader()
const materialsContainer = document.querySelector('.materials')

object.materials.forEach(material => {
    if(material.type === 'texture' || material.type === 'color') {
        const materialFinishes = finishes.find(object => object.type === material.materialType)
        const defaultFinish = materialFinishes.materials.find(object => object.name === material.materialDefault)
    
        if(material.type === 'texture') {
            const materialToFinishesSelection = { type: 'texture', finishes: materialFinishes, default: defaultFinish }
            materialsFinishes.push(materialToFinishesSelection)

            const texture = textureLoader.load(defaultFinish.url, function() {
                texture.flipY = false
                // texture.encoding = THREE.sRGBEncoding
                texture.wrapS = THREE.RepeatWrapping
                texture.wrapT = THREE.RepeatWrapping
                
                material.texture = texture
            })
        }

        if(material.type === 'color') {
            const materialToFinishesSelection = { type: 'color', finishes: materialFinishes, default: defaultFinish }
            materialsFinishes.push(materialToFinishesSelection)
        }
    }
})
generateFinishesThumbs(materialsFinishes)

function changeTexture(e) {
    const materialType = e.target.closest('.materials__group').getAttribute('data-type')
    const materialName = e.target.closest('.materials__group').getAttribute('data-name')
    const materialDefault = e.target.getAttribute('data-name')
    
    const materialFinishes = finishes.find(object => object.type === materialName)
    const newFinish = materialFinishes.materials.find(object => object.name === materialDefault)

    switch(materialType) {
        case 'texture' :
            const texture = textureLoader.load(newFinish.url, function() {
                texture.flipY = false
                // texture.encoding = THREE.sRGBEncoding
                texture.wrapS = THREE.RepeatWrapping
                texture.wrapT = THREE.RepeatWrapping
                
                materialFinishes.objMaterial.map = texture
            })
            break;

        case 'color' :
            materialFinishes.objMaterial.color.set(newFinish.color)
            break;
    }
}

function generateFinishesThumbs(materialsFinishesMaterials) {
    materialsFinishesMaterials.forEach((item, index) => {
        let thumbs = ''
        const type = item.type
        
        item.finishes.materials.forEach(item => {
            switch(type) {
                case 'texture' :
                    thumbs +=
                        `<div class="materials__thumb" title="${item.title}"  data-name="${item.name}">
                            <img src='${item.url}'>
                        </div>`
                    break;

                case 'color' :
                    thumbs +=
                        `<div class="materials__thumb" title="${item.title}"  data-name="${item.name}" style="background-color: ${item.color};">
                        </div>`
                    break;
            }
        })

        materialsContainer.innerHTML += 
            `<div class="materials__group" data-type="${type}" data-name="${item.finishes.type}">
                <div class="materials__group__title">${item.finishes.name}</div>
                <div class="materials__group__thumbs">${thumbs}</div>
            </div>`
    })

    document.querySelectorAll('.materials__thumb').forEach(item => item.addEventListener('click', changeTexture))
}




/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

gltfLoader.load(
    `/models/${object.folder}/${object.url}`,
    (gltf) => {
        gltfLoaded = gltf
        scene.add(gltf.scene)

        var cube_bbox = new THREE.Box3();
        cube_bbox.setFromObject( gltf.scene );
        const cubeHeight = cube_bbox.max.y - cube_bbox.min.y

        controls.target.set(0, cubeHeight / 2, 0)
        camera.position.set(camera.position.x, cubeHeight * 1.5, camera.position.z)

        loadTextures()
    }
)

function loadTextures() {
    const materials = []

    gltfLoaded.scene.traverse((child) => {
        if(child.isMesh) {
            if(!materials.includes(child.material)) {
                materials.push(child.material)
                
                object.materials.forEach(material => {
                    if(child.material.name === material.materialName) {
                        switch(material.type) {
                            case 'texture' :
                                const materialFinishesTexture = finishes.find(object => object.type === material.materialType)
                                materialFinishesTexture.objMaterial = child.material
                                // child.material.map = material.texture

                                if('opacity' in material) {
                                    child.material.transparent = true
                                    // child.material.opacity = material.opacity
                                }
                                break;
                            
                            case 'color' :
                                const materialFinishesColor = finishes.find(object => object.type === material.materialType)
                                const defaultFinishColor = materialFinishesColor.materials.find(object => object.name === material.materialDefault)
                                materialFinishesColor.objMaterial = child.material
                                // child.material.color.set(defaultFinishColor.color)
                                break;

                            case 'color_glass' :
                                child.material.color.set(material.color)

                                if('opacity' in material) {
                                    child.material.transparent = true
                                    child.material.opacity = material.opacity
                                }
                                break;
                        }
                    }
                })
            }
        }
        // child.castShadow = true
        // child.receiveShadow = true
    })
}

// setTimeout(loadTextures, 1000)



/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(params.ambientLightColor, 0.8)
scene.add(ambientLight)
ambientLightFolder.addColor(params, 'ambientLightColor').onChange(value => { ambientLight.color.setHex(value.toString().replace('#', '0x')) }).name('Color')
ambientLightFolder.add(ambientLight, 'intensity').min(0).max(5).step(0.1).name('Intensity')

const directionalLight = new THREE.DirectionalLight(0xf4f4f4, 0.8)
// directionalLight.castShadow = true
// directionalLight.shadow.mapSize.set(1024 * 1, 1024 * 1)
// directionalLight.shadow.camera.far = 15
// directionalLight.shadow.camera.left = - 7
// directionalLight.shadow.camera.top = 7
// directionalLight.shadow.camera.right = 7
// directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(-2.4,0.7,-0.5)
scene.add(directionalLight)

const helper = new THREE.DirectionalLightHelper(directionalLight, 1, 0x00ff00)
helper.visible = params.directionalLightHelperVisible
scene.add(helper)

directionalLightFolder.addColor(params, 'directionalLightColor').onChange(value => { directionalLight.color.setHex(value.toString().replace('#', '0x')) }).name('Color')
directionalLightFolder.add(directionalLight, 'intensity').min(0).max(5).step(0.1).name('Intensity')
directionalLightFolder.add(directionalLight.position, 'x').min(-5).max(5).step(0.1).name('PositionX')
directionalLightFolder.add(directionalLight.position, 'y').min(-5).max(5).step(0.1).name('PositionY')
directionalLightFolder.add(directionalLight.position, 'z').min(-5).max(5).step(0.1).name('PositionZ')
directionalLightFolder.add(params, 'directionalLightHelperVisible').name('ambientLightIntensity2').onChange(value => { helper.visible = value }).name('Visible')

/**
 * Sizes
 */
const sizes = {
    width: canvasContainer.offsetWidth,
    height: canvasContainer.offsetHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = canvasContainer.offsetHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height // PerspectiveCamera
    // camera.left = -1 * sizes.width / sizes.height;
    // camera.right = 1 * sizes.width / sizes.height;
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 100) // PerspectiveCamera
// const camera = new THREE.OrthographicCamera(-1 * sizes.width / sizes.height, 1 * sizes.width / sizes.height, 1, -1, 1, 100)
camera.position.set(-object.cameraDistance, 0, object.cameraDistance)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.5, 0)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    // powerPreference: 'high-performance'
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// renderer.shadowMap.enabled = params.directionalLightShadowMapVisible
// renderer.shadowMap.type = THREE.PCFSoftShadowMap
// // renderer.shadowMap.autoUpdate = false
// // renderer.shadowMap.needsUpdate = true
// directionalLightFolder.add(params, 'directionalLightShadowMapVisible').onChange(value => { 
//     directionalLight.castShadow = value 
//     directionalLight.needsUpdate = true 
//     renderer.shadowMap.needsUpdate = true
// }).name('Shadow')

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

window.onhashchange = function() {
    window.location.reload() 
}

tick()