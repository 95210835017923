export const finishes = [
  {
    type: 'madeira',
    name: 'Madeira',
    materials: [
      {
        name: 'cumaru',
        title: 'Cumaru',
        url: '/finishes/madeira/cumaru.jpg',
      },
      {
        name: 'sucupira',
        title: 'Sucupira',
        url: '/finishes/madeira/sucupira.jpg',
      },
      {
        name: 'cumaru-ebanizado',
        title: 'Cumaru Ebanizado',
        url: '/finishes/madeira/cumaru-ebanizado.jpg',
      },
    ]
  },
  {
    type: 'tela',
    name: 'Tela',
    materials: [
      { 
        name: 'ts01',
        title: 'TS01 - TELA PRETA',
        url: '/finishes/tela/ts01.jpg',
      },
      { 
        name: 'ts04',
        title: 'TS04 - TELA BRANCA',
        url: '/finishes/tela/ts04.jpg',
      },
      { 
        name: 'ts05',
        title: 'TS05 - TELA CINZA',
        url: '/finishes/tela/ts05.jpg',
      },
      { 
        name: 'ts11',
        title: 'TS11 - TELA CAFÉ',
        url: '/finishes/tela/ts11.jpg',
      },
      { 
        name: 'ts12',
        title: 'TS12 - TELA AZUL ÍNDIGO',
        url: '/finishes/tela/ts12.jpg',
      },
      { 
        name: 'ts13',
        title: 'TS13 - TELA NUDE',
        url: '/finishes/tela/ts13.jpg',
      },
      { 
        name: 'ts14',
        title: 'TS14 - TELA SLING GRAFITE',
        url: '/finishes/tela/ts14.jpg',
      },
      { 
        name: 'ts15',
        title: 'TS15 - TELA SLING LINHO',
        url: '/finishes/tela/ts15.jpg',
      },
      { 
        name: 'tsf01',
        title: 'TSF01 - TELA CINZA MESCLA ESPECIAL',
        url: '/finishes/tela/tsf01.jpg',
      },
    ]
  },
  {
    type: 'fibra',
    name: 'Fibra',
    materials: [
      {
        name: 'fb42',
        title: 'FB42 - ESTONADO TABACO',
        url: '/finishes/fibra/fb42.jpg',
      },
      {
        name: 'fb43',
        title: 'FB43 - ESTONADO CRU',
        url: '/finishes/fibra/fb43.jpg',
      },
      {
        name: 'fb44',
        title: 'FB44 - ESTONADO NATURAL',
        url: '/finishes/fibra/fb44.jpg',
      },
      {
        name: 'fb45',
        title: 'FB45 - TEXTURIZADO WOOD',
        url: '/finishes/fibra/fb45.jpg',
      },
      {
        name: 'fb46',
        title: 'FB46 - MESCLADO TERRA',
        url: '/finishes/fibra/fb46.jpg',
      },
      {
        name: 'fb47',
        title: 'FB47 - MESCLADO NATURE',
        url: '/finishes/fibra/fb47.jpg',
      },
      {
        name: 'fb55',
        title: 'FB55 - TEXTURIZADO CASTANHO',
        url: '/finishes/fibra/fb55.jpg',
      },
      {
        name: 'fb72',
        title: 'FB72 - VINHO ESTONADO',
        url: '/finishes/fibra/fb72.jpg',
      },
      {
        name: 'fb73',
        title: 'FB73 - AZUL CLARO ESTONADO',
        url: '/finishes/fibra/fb73.jpg',
      },
      {
        name: 'fb74',
        title: 'FB74 - OLIVA ESTONADO',
        url: '/finishes/fibra/fb74.jpg',
      },
      {
        name: 'fb75',
        title: 'FB75 - CINZA ESTONADO',
        url: '/finishes/fibra/fb75.jpg',
      },
      {
        name: 'fb76',
        title: 'FB76 - LARANJA ESTONADO',
        url: '/finishes/fibra/fb76.jpg',
      },
      {
        name: 'fb77',
        title: 'FB77 - VERDE CLARO ESTONADO',
        url: '/finishes/fibra/fb77.jpg',
      },
      {
        name: 'fb78',
        title: 'FB78 - BEGE ESTONADO',
        url: '/finishes/fibra/fb78.jpg',
      },
      {
        name: 'fb79',
        title: 'FB79 - VERMELHO ESTONADO',
        url: '/finishes/fibra/fb79.jpg',
      },
      {
        name: 'fb80',
        title: 'FB80 - PRETO ESTONADO',
        url: '/finishes/fibra/fb80.jpg',
      },
      {
        name: 'fb81',
        title: 'FB81 - AZUL MARINHO ESTONADO',
        url: '/finishes/fibra/fb81.jpg',
      },
      {
        name: 'fb82',
        title: 'FB82 - BRANCO ESTONADO',
        url: '/finishes/fibra/fb82.jpg',
      },
      {
        name: 'fb85',
        title: 'FB85 - CENOURA ESTONADO',
        url: '/finishes/fibra/fb85.jpg',
      },
      {
        name: 'fb86',
        title: 'FB86 - VERDE MUSGO ESTONADO',
        url: '/finishes/fibra/fb86.jpg',
      },
      {
        name: 'fb88',
        title: 'FB88 - MOSTARDA ESTONADO',
        url: '/finishes/fibra/fb88.jpg',
      },
      {
        name: 'fb89',
        title: 'FB89 - OFF WHITE ESTONADO',
        url: '/finishes/fibra/fb89.jpg',
      },
      {
        name: 'fb90',
        title: 'FB90 - TERRACOTA ESTONADO',
        url: '/finishes/fibra/fb90.jpg',
      },
    ]
  },
  {
    type: 'tecido_poliester',
    name: 'Tecido Poliéster',
    materials: [
      {
        name: 'po158',
        title: 'PO 158',
        url: '/finishes/tecido_poliester/po158.jpg',
      },
      {
        name: 'po159',
        title: 'PO 159',
        url: '/finishes/tecido_poliester/po159.jpg',
      },
      {
        name: 'po162',
        title: 'PO 162',
        url: '/finishes/tecido_poliester/po162.jpg',
      },
      {
        name: 'po162',
        title: 'PO 162',
        url: '/finishes/tecido_poliester/po162.jpg',
      },
      {
        name: 'po164',
        title: 'PO 164',
        url: '/finishes/tecido_poliester/po164.jpg',
      },
      {
        name: 'po165',
        title: 'PO 165',
        url: '/finishes/tecido_poliester/po165.jpg',
      },
      {
        name: 'po167',
        title: 'PO 167',
        url: '/finishes/tecido_poliester/po167.jpg',
      },
      {
        name: 'po169',
        title: 'PO 169',
        url: '/finishes/tecido_poliester/po169.jpg',
      },
      {
        name: 'po172',
        title: 'PO 172',
        url: '/finishes/tecido_poliester/po172.jpg',
      },
      {
        name: 'po180',
        title: 'PO 180',
        url: '/finishes/tecido_poliester/po180.jpg',
      },
      {
        name: 'po183',
        title: 'PO 183',
        url: '/finishes/tecido_poliester/po183.jpg',
      },
      {
        name: 'po191',
        title: 'PO 191',
        url: '/finishes/tecido_poliester/po191.jpg',
      },
      {
        name: 'po194',
        title: 'PO 194',
        url: '/finishes/tecido_poliester/po194.jpg',
      },
      {
        name: 'po197',
        title: 'PO 197',
        url: '/finishes/tecido_poliester/po197.jpg',
      },
      {
        name: 'po198',
        title: 'PO 198',
        url: '/finishes/tecido_poliester/po198.jpg',
      },
      {
        name: 'po199',
        title: 'PO 199',
        url: '/finishes/tecido_poliester/po199.jpg',
      },
      {
        name: 'po200',
        title: 'PO 200',
        url: '/finishes/tecido_poliester/po200.jpg',
      },
      {
        name: 'po201',
        title: 'PO 201',
        url: '/finishes/tecido_poliester/po201.jpg',
      },
      {
        name: 'po202',
        title: 'PO 202',
        url: '/finishes/tecido_poliester/po202.jpg',
      },
      {
        name: 'po203',
        title: 'PO 203',
        url: '/finishes/tecido_poliester/po203.jpg',
      },
      {
        name: 'po204',
        title: 'PO 204',
        url: '/finishes/tecido_poliester/po204.jpg',
      },
      {
        name: 'po205',
        title: 'PO 205',
        url: '/finishes/tecido_poliester/po205.jpg',
      },
      {
        name: 'po206',
        title: 'PO 206',
        url: '/finishes/tecido_poliester/po206.jpg',
      },
      {
        name: 'plus01',
        title: 'PLUS 01',
        url: '/finishes/tecido_poliester/plus01.jpg',
      },
      {
        name: 'plus02',
        title: 'PLUS 02',
        url: '/finishes/tecido_poliester/plus02.jpg',
      },
      {
        name: 'plus03',
        title: 'PLUS 03',
        url: '/finishes/tecido_poliester/plus03.jpg',
      },
      {
        name: 'plus04',
        title: 'PLUS 04',
        url: '/finishes/tecido_poliester/plus04.jpg',
      },
      {
        name: 'plus05',
        title: 'PLUS 05',
        url: '/finishes/tecido_poliester/plus05.jpg',
      },
      {
        name: 'plus06',
        title: 'PLUS 06',
        url: '/finishes/tecido_poliester/plus06.jpg',
      },
      {
        name: 'plus07',
        title: 'PLUS 07',
        url: '/finishes/tecido_poliester/plus07.jpg',
      },
      {
        name: 'plus08',
        title: 'PLUS 08',
        url: '/finishes/tecido_poliester/plus08.jpg',
      },
      {
        name: 'plus09',
        title: 'PLUS 09',
        url: '/finishes/tecido_poliester/plus09.jpg',
      },
      {
        name: 'plus10',
        title: 'PLUS 10',
        url: '/finishes/tecido_poliester/plus10.jpg',
      },
      {
        name: 'tsa01',
        title: 'TSA 01',
        url: '/finishes/tecido_poliester/tsa01.jpg',
      },
      {
        name: 'tsa02',
        title: 'TSA 02',
        url: '/finishes/tecido_poliester/tsa02.jpg',
      },
      {
        name: 'tsa03',
        title: 'TSA 03',
        url: '/finishes/tecido_poliester/tsa03.jpg',
      },
      {
        name: 'tsa04',
        title: 'TSA 04',
        url: '/finishes/tecido_poliester/tsa04.jpg',
      },
      {
        name: 'tsa07',
        title: 'TSA 07',
        url: '/finishes/tecido_poliester/tsa07.jpg',
      },
      {
        name: 'tsa12',
        title: 'TSA 12',
        url: '/finishes/tecido_poliester/tsa12.jpg',
      },
      
    ]
  },
  {
    type: 'corda',
    name: 'Corda',
    materials: [
      { 
        name: 'cor01',
        title: 'COR01 - VINHO',
        url: '/finishes/corda/cor01.jpg',
      },
      { 
        name: 'cor02',
        title: 'COR02 - BRANCO',
        url: '/finishes/corda/cor02.jpg',
      },
      { 
        name: 'cor03',
        title: 'COR03 - CAMURÇA',
        url: '/finishes/corda/cor03.jpg',
      },
      { 
        name: 'cor04',
        title: 'COR04 - MARROM',
        url: '/finishes/corda/cor04.jpg',
      },
      { 
        name: 'cor05',
        title: 'COR05 - PRETO',
        url: '/finishes/corda/cor05.jpg',
      },
      { 
        name: 'cor06',
        title: 'COR06 - AZUL ACIZENTADO',
        url: '/finishes/corda/cor06.jpg',
      },
      {
        name: 'cor07',
        title: 'COR07 - CINZA CLARO',
        url: 'finishes/corda/cor07.jpg',
      },
      {
        name: 'cor08',
        title: 'COR08 - CINZA ESCURO',
        url: 'finishes/corda/cor08.jpg',
      },
      {
        name: 'cor09',
        title: 'COR09 - VERDE MUSGO',
        url: 'finishes/corda/cor09.jpg',
      },
      {
        name: 'cor13',
        title: 'COR13 - AZUL MARINHO',
        url: 'finishes/corda/cor13.jpg',
      },
      {
        name: 'cor15',
        title: 'COR15 - AREIA',
        url: 'finishes/corda/cor15.jpg',
      },
      {
        name: 'cor16',
        title: 'COR16 - TERRACOTA',
        url: 'finishes/corda/cor16.jpg',
      },
      {
        name: 'cor17',
        title: 'COR17 - VERDE CLARO',
        url: 'finishes/corda/cor17.jpg',
      },
    ]
  },
  {
    type: 'corda_cor',
    name: 'Corda',
    materials: [
      { 
        name: 'cor01',
        title: 'COR01 - VINHO',
        color: '#774c58',
      },
      { 
        name: 'cor02',
        title: 'COR02 - BRANCO',
        color: '#c8c9c4',
      },
      { 
        name: 'cor03',
        title: 'COR03 - CAMURÇA',
        color: '#ab9a87',
      },
      { 
        name: 'cor04',
        title: 'COR04 - MARROM',
        color: '#362d2c',
      },
      { 
        name: 'cor05',
        title: 'COR05 - PRETO',
        color: '#0a0a0a',
      },
      { 
        name: 'cor06',
        title: 'COR06 - AZUL ACIZENTADO',
        color: '#b0becf',
      },
      {
        name: 'cor07',
        title: 'COR07 - CINZA CLARO',
        color: '#a8a6a4',
      },
      {
        name: 'cor08',
        title: 'COR08 - CINZA ESCURO',
        color: '#424850',
      },
      {
        name: 'cor09',
        title: 'COR09 - VERDE MUSGO',
        color: '#373831',
      },
      {
        name: 'cor13',
        title: 'COR13 - AZUL MARINHO',
        color: '#202231',
      },
      {
        name: 'cor15',
        title: 'COR15 - AREIA',
        color: '#b2a496',
      },
      {
        name: 'cor16',
        title: 'COR16 - TERRACOTA',
        color: '#7c452c',
      },
      {
        name: 'cor17',
        title: 'COR17 - VERDE CLARO',
        color: '#7f877e',
      },
    ]
  },
  {
    type: 'aluminio',
    name: 'Alumínio',
    materials: [
      {
        name: 'al04',
        title: 'AL04 - BEGE TXT FOSCO',
        color: '#b7ae9c'
      },
      {
        name: 'al05',
        title: 'AL05 - BRANCO TXT',
        color: '#dadad8'
      },
      {
        name: 'al06',
        title: 'AL06 - MARROM TXT FOSCO',
        color: '#5d504c',
      },
      {
        name: 'al09',
        title: 'AL09 - PRETO TXT FOSCO',
        color: '#1d1d1f',
      },
      {
        name: 'al39',
        title: 'AL39 - CAMURÇA',
        color: '#968a7e',
      },
      {
        name: 'al40',
        title: 'AL40 - AZUL MARINHO TXT',
        color: '#25293a',
      },
      {
        name: 'al41',
        title: 'AL41 - CORTÉN',
        color: '#2c1811',
      },
      {
        name: 'al42',
        title: 'AL42 - CINZA CLARO ACETINADO',
        color: '#95C5E1'
      },
      {
        name: 'al43',
        title: 'AL43 - CINZA ESCURO ACETINADO',
        color: '#454247',
      },
      {
        name: 'al44',
        title: 'AL44 - AMARELO ESCURO ACETINADO',
        color: '#cf842e',
      },
      {
        name: 'al45',
        title: 'AL45 - VERDE MÉDIO ACETINADO',
        color: '#687c63',
      },
      {
        name: 'al46',
        title: 'AL46 - CAPUCCINO TXT ACETINADO',
        color: '#71655c',
      },
      {
        name: 'al48',
        title: 'AL48 - AZUL CLARO ACETINADO',
        color: '#7499b1',
      },
      {
        name: 'al49',
        title: 'AL49 - VERDE MUSGO ACETINADO',
        color: '#1f3036',
      },
      {
        name: 'al51',
        title: 'AL51 - VERMELHO ACETINADO',
        color: '#863135',
      },
      {
        name: 'al52',
        title: 'AL52 - TERRACOTA',
        color: '#543013',
      },
      {
        name: 'al53',
        title: 'AL53 - OFF WHITE TXT',
        color: '#c8c4bf',
      },
    ]
  }
]