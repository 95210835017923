export const objects = [
  { // 0
    name: 'Banqueta Loop',
    folder: 'banqueta-loop',
    url: 'Banqueta-Loop.gltf',
    materials: [
        {
            type: 'texture',
            materialName: 'BANQ-LOOP_TECIDO',
            materialType: 'tecido_poliester',
            materialDefault: 'plus09',
        },
        {
            type: 'texture',
            materialName: 'BANQ-LOOP_CORDA',
            materialType: 'corda',
            materialDefault: 'cor06',
        },
        {
            type: 'color',
            materialName: 'BANQ-LOOP_AÇO',
            materialType: 'aluminio',
            materialDefault: 'al48',
        }
    ],
    cameraDistance: 1.7,
  },
  { // 1
    name: 'Mesa de Centro 2 Tampos P Drift',
    folder: 'mesa-de-centro-2-tampos_p-drift',
    url: 'Mesa_Centro_78_2Tampos_Drift.gltf',
    materials: [
        {
            type: 'color',
            materialName: '_metal',
            materialType: 'aluminio',
            materialDefault: 'al46',
        },
        {
            type: 'color_glass',
            materialName: 'glossy black1',
            color: '#ffffff',
            opacity: 0.32,
        }
    ],
    cameraDistance: 1.7,
  },
  { // 2
    name: 'Mesa Lateral G Aram',
    folder: 'mesa-lateral-g-aram',
    url: 'Mesa_Lateral_A40_D55_Aram.glb',
    materials: [
        {
            type: 'texture',
            materialName: 'D55-ARAM_CORDA',
            materialType: 'corda',
            materialDefault: 'cor13',
        },
        {
            type: 'texture',
            materialName: 'D55-ARAM_MADEIRA',
            materialType: 'madeira',
            materialDefault: 'cumaru',
        },
    ],
    cameraDistance: 1
  },
  { // 3
    name: 'Banqueta Lili',
    folder: 'banqueta-lili',
    url: 'Banqueta_Lili.glb',
    materials: [
        {
            type: 'color',
            materialName: 'BANQ-GIRASSOL_CORDAS',
            materialType: 'corda_cor',
            materialDefault: 'cor01',
        },
        {
            type: 'color',
            materialName: 'CAD-GIRASSOL_ESTRUTURA',
            materialType: 'aluminio',
            materialDefault: 'al53',
        }
    ],
    cameraDistance: 1.7
  },
  { // 4
    name: 'Banqueta Tela Isis',
    folder: 'banqueta-tela-isis',
    url: 'Banqueta_Tela_Isis.gltf',
    materials: [
        {
            type: 'texture',
            materialName: 'BANQ-ISIS_TELA',
            materialType: 'tela',
            materialDefault: 'ts01',
            opacity: 1,
        },
        {
            type: 'texture',
            materialName: 'BANQ-ISIS_MADEIRA',
            materialType: 'madeira',
            materialDefault: 'cumaru',
        },
        {
            type: 'color',
            materialName: 'BANQ-ISIS_BORDA',
            materialType: 'aluminio',
            materialDefault: 'al09',
        }
    ],
    cameraDistance: 1.9
  },
  { // 5
    name: 'Bird House Gota',
    folder: 'birdhouse-gotinha',
    url: 'Birdhouse_Gotinha.gltf',
    materials: [
        {
            type: 'texture',
            materialName: '[Color A02]',
            materialType: 'fibra',
            materialDefault: 'fb76',
        },
        {
            type: 'texture',
            materialName: '[Color A01]',
            materialType: 'madeira',
            materialDefault: 'cumaru',
        },
        // {
        //     type: 'color',
        //     materialName: 'Material',
        //     color: [
        //         '#E7E7E7',
        //     ],
        // },
        {
            type: 'color',
            materialName: '[Color M08]',
            materialType: 'corda_cor',
            materialDefault: 'cor05',
        }
    ],
    cameraDistance: 1
  },
  { // 6
    name: 'Cachepot M Acessórios',
    folder: 'cachepot-com-rodas-a60xd70-acessorio',
    url: 'Cachepot com Rodas_A60xD70 - Acessorio.gltf',
    materials: [
        {
            type: 'texture',
            materialName: 'VARANDA-CACHEPOT_',
            materialType: 'fibra',
            materialDefault: 'fb43',
        },
    ],
    cameraDistance: 1.5
  },
  { // 7
    name: 'Cadeira Giratória Anauê',
    folder: 'cadeira-giratoria-anaue',
    url: 'Cadeira_Giratoria_Anaue.gltf',
    materials: [
        {
            type: 'texture',
            materialName: '[Color L11]',
            materialType: 'tecido_poliester',
            materialDefault: 'po198',
        },
        {
            type: 'color',
            materialName: '[Color A02]',
            materialType: 'aluminio',
            materialDefault: 'al39',
        },
        {
            type: 'color',
            materialName: '[Color B02]',
            materialType: 'corda_cor',
            materialDefault: 'cor15',
        },
        // {
        //     type: 'color',
        //     materialName: '[Color M06]',
        //     color: [
        //         '#555555',
        //     ],
        // },
    ],
    cameraDistance: 1.5
  },
  { // 8
    name: 'Carro bar Colibri',
    folder: 'carrinho-bar-colibri',
    url: 'Carrinho_Bar_Colibri.gltf',
    materials: [
        {
            type: 'texture',
            materialName: '*',
            materialType: 'madeira',
            materialDefault: 'cumaru',
        },
        // {
        //     type: 'texture',
        //     materialName: '*5',
        //     textureSrc: [
        //         '0389.png',
        //     ],
        //     opacity: 0.5,
        // },
        // {
        //     type: 'texture',
        //     materialName: '*1',
        //     textureSrc: [
        //         'cumaru horizontal.jpg',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: '*4',
        //     color: [
        //         '#714023',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: '*2',
        //     color: [
        //         '#38373A',
        //     ],
        // },
    ],
    cameraDistance: 1.5
  },
  { // 9
    name: 'Chaise Bask',
    folder: 'chaise-braco-direito-bask',
    url: 'Chaise_Braco_Direito_Bask.gltf',
    materials: [
        {
            type: 'texture',
            materialName: '_tecido',
            materialType: 'tecido_poliester',
            materialDefault: 'tsa03',
        },
        {
            type: 'color',
            materialName: '_aluminio',
            materialType: 'aluminio',
            materialDefault: 'al46',
        },
    ],
    cameraDistance: 1.5
  },
  { // 10
    name: 'Chaise Suspensa Lazy Day',
    folder: 'chaise-lazy-day',
    url: 'Chaise_LazyDay_Suspensa.gltf',
    materials: [
        {
            type: 'texture',
            materialName: 'LazyDay_Corda',
            materialType: 'corda',
            materialDefault: 'cor09',
        },
        {
            type: 'texture',
            materialName: 'LazyDay_Alm2',
            materialType: 'tecido_poliester',
            materialDefault: 'plus03',
        },
        // {
        //     type: 'texture',
        //     materialName: 'LazyDay_Alm1',
        //     materialType: 'tecido_poliester',
        //     materialDefault: 'po183',
        // },
        // {
        //     type: 'texture',
        //     materialName: 'LazyDay_Alm3',
        //     materialType: 'tecido_poliester',
        //     materialDefault: 'po191',
        // },
        {
            type: 'color',
            materialName: 'LazyDay_Metal',
            materialType: 'aluminio',
            materialDefault: 'al40',
        },
    ],
    cameraDistance: 3
  },
  { // 11
    name: 'Luminária Pendente Bask Nautic',
    folder: 'conjunto-luminaria-bask',
    url: 'Conjunto_Luminaria_Pendente_Bask.gltf',
    materials: [
        {
            type: 'texture',
            materialName: 'LUMI-PEND_BASK_WOOD1',
            materialType: 'madeira',
            materialDefault: 'sucupira',
        },
        // {
        //     type: 'texture',
        //     materialName: 'LUMI-PEND_BASK_WOOD1',
        //     textureSrc: [
        //         'Natural Oak_MAP.jpg',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-G_03',
        //     color: [
        //         '#0074BE',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-G_1',
        //     color: [
        //         '#A5CDF0',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-G_CABO',
        //     color: [
        //         '#1E1E1E',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-M_CABO',
        //     color: [
        //         0x1E1E1E,
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-M_1',
        //     color: [
        //         '#FE2202',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-P_1',
        //     color: [
        //         '#008934',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-P_CABO',
        //     color: [
        //         '#181818',
        //     ],
        // },
        // {
        //     type: 'color',
        //     materialName: 'LUMI-PEND_BASK-P_01',
        //     color: [
        //         '#06BA29',
        //     ],
        // },
    ],
    cameraDistance: 2
  } 
]

const thumbsContainer = document.querySelector('.thumbs')

objects.forEach((item, index) => {
    thumbsContainer.innerHTML += 
        `<a href='/#${index}' class="thumb" title="${item.name}">
            <img src='/models/${item.folder}/thumb.jpg' alt="">
        </a>`;
})